import React from "react";
import { useParams } from "react-router-dom";

import Page from "../components/Page";
import NavBarContainer from "../containers/NavBarContainer";
import Content from "../components/Content";
import TopBar from "../components/TopBar";

export default function Registration({ isLead }) {
  const { id } = useParams();

  const ContractorFormRef = React.useRef(null);
  const BankDetailsFormRef = React.useRef(null);
  const AwrFormRef = React.useRef(null);
  const ContractFormRef = React.useRef(null);
  const AgencyFormRef = React.useRef(null);
  const FeesFormRef = React.useRef(null);
  const CompareUmbrellaFormRef = React.useRef(null);
  const RecommendationFormRef = React.useRef(null);

  React.useEffect(() => {
    if (ContractorFormRef.current) {
      const promise = window.MODULE_LOADER.loadModule("registrations").then(() => {
        return window.REGISTRATIONS.renderContractorForm({
          element: ContractorFormRef.current,
          id: id,
        });
      });

      return () => promise.then((unmount) => unmount());
    }
  }, [ContractorFormRef.current]);

  React.useEffect(() => {
    if (BankDetailsFormRef.current) {
      const promise = window.MODULE_LOADER.loadModule("registrations").then(() => {
        return window.REGISTRATIONS.renderBankDetailsForm({
          element: BankDetailsFormRef.current,
          id: id,
        });
      });

      return () => promise.then((unmount) => unmount());
    }
  }, [BankDetailsFormRef.current]);

  React.useEffect(() => {
    if (AwrFormRef.current) {
      const promise = window.MODULE_LOADER.loadModule("registrations").then(() => {
        return window.REGISTRATIONS.renderAwrForm({
          element: AwrFormRef.current,
          id: id,
        });
      });

      return () => promise.then((unmount) => unmount());
    }
  }, [AwrFormRef.current]);

  React.useEffect(() => {
    if (ContractFormRef.current) {
      const promise = window.MODULE_LOADER.loadModule("registrations").then(() => {
        return window.REGISTRATIONS.renderContractForm({
          element: ContractFormRef.current,
          id: id,
        });
      });

      return () => promise.then((unmount) => unmount());
    }
  }, [ContractFormRef.current]);

  React.useEffect(() => {
    if (AgencyFormRef.current) {
      const promise = window.MODULE_LOADER.loadModule("registrations").then(() => {
        return window.REGISTRATIONS.renderAgencyForm({
          element: AgencyFormRef.current,
          id: id,
        });
      });

      return () => promise.then((unmount) => unmount());
    }
  }, [AgencyFormRef.current]);

  React.useEffect(() => {
    if (FeesFormRef.current) {
      const promise = window.MODULE_LOADER.loadModule("registrations").then(() => {
        return window.REGISTRATIONS.renderFeesForm({
          element: FeesFormRef.current,
          id: id,
        });
      });

      return () => promise.then((unmount) => unmount());
    }
  }, [FeesFormRef.current]);

  React.useEffect(() => {
    if (CompareUmbrellaFormRef.current) {
      const promise = window.MODULE_LOADER.loadModule("registrations").then(() => {
        return window.REGISTRATIONS.renderCompareAgainstUmbrellaForm({
          element: CompareUmbrellaFormRef.current,
          id: id,
        });
      });

      return () => promise.then((unmount) => unmount());
    }
  }, [CompareUmbrellaFormRef.current]);

  React.useEffect(() => {
    if (RecommendationFormRef.current) {
      const promise = window.MODULE_LOADER.loadModule("registrations").then(() => {
        return window.REGISTRATIONS.renderRecommendationForm({
          element: RecommendationFormRef.current,
          id: id,
        });
      });

      return () => promise.then((unmount) => unmount());
    }
  }, [RecommendationFormRef.current]);

  return (
    <Page>
      <NavBarContainer />
      <Content>
        <TopBar>Registration</TopBar>
        <div style={{ display: "flex", flexGrow: 1, flexDirection: "row" }}>
          {Boolean(isLead) && (
            <Content id="column-1">
              <div style={{ margin: "10px 5px 5px 10px" }} ref={ContractorFormRef}></div>
              <div style={{ margin: "5px 5px 5px 10px" }} ref={BankDetailsFormRef}></div>
            </Content>
          )}
          <Content id="column-2">
            <div style={{ margin: "10px 5px 5px 5px" }} ref={AgencyFormRef}></div>
            <div style={{ margin: "5px 5px 5px 5px" }} ref={AwrFormRef}></div>
            <div style={{ margin: "5px 5px 5px 5px" }}>PSC/PS Pro Information</div>
          </Content>
          <Content id="column-3">
            <div style={{ margin: "10px 5px 5px 5px" }} ref={FeesFormRef}></div>
            <div style={{ margin: "5px 5px 5px 5px" }} ref={CompareUmbrellaFormRef}></div>
            <div style={{ margin: "10px 5px 5px 5px" }} ref={ContractFormRef}></div>
          </Content>
          <Content id="column-4">
            <div style={{ margin: "10px 10px 5px 5px" }} ref={RecommendationFormRef}></div>
            <div style={{ margin: "5px 10px 5px 5px" }}>Communications</div>
            <div style={{ margin: "5px 10px 5px 5px" }}>Related Registrations</div>
          </Content>
        </div>
      </Content>
    </Page>
  );
}
