import React from "react";

import Page from "../components/Page";
import Content from "../components/Content";
import TopBar from "../components/TopBar";
import NavBarContainer from "../containers/NavBarContainer";

function Agencies() {
  const agenciesPageRef = React.useRef(null);

  React.useEffect(() => {
    if (agenciesPageRef.current) {
      window.MODULE_LOADER.loadModule("agencies").then(() => {
        window.AGENCIES.renderAgenciesTable({
          element: agenciesPageRef.current
        });
      });
    }
  }, [agenciesPageRef.current]);

  return (
    <Page>
      <NavBarContainer />
      <Content>
        <TopBar>Agencies</TopBar>
        <div style={{ height: "calc(100% - 64px)" }} ref={agenciesPageRef}></div>
      </Content>
    </Page>
  );
}

export default Agencies;
