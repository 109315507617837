import React from "react";
import Page from "../components/Page";
import Content from "../components/Content";

function Contractors() {
  const loginPageRef = React.useRef(null);

  React.useEffect(() => {
    if (loginPageRef.current) {
      return window.AUTH.renderRefreshLogin({ element: loginPageRef.current });
    }
  }, [loginPageRef.current]);

  return (
    <Page>
      <Content>
        <div ref={loginPageRef}></div>
      </Content>
    </Page>
  );
}

export default Contractors;
