import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import logoGreen from "../images/logo-green.png";

const useStyles = makeStyles({
  brand: {
    width: 126,
    height: 30,
    backgroundImage: `url(${logoGreen})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat"
  }
});

function PeopleLogo() {
  const classes = useStyles();

  return <div className={classes.brand}></div>;
}

export default PeopleLogo;
