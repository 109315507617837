import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    height: 80,
    width: 80,
    padding: "10px 0",
    "&:hover": {
      background: "#e0e0e0"
    }
  },
  icon: {
    "&>*": {
      fontSize: 40
    },
    textAlign: "center",
    height: 42
  },
  title: {
    height: 15,
    lineHeight: "13px",
    textAlign: "center",
    fontSize: 10
  }
});

function NavigationBarLink({ icon, title, component, ...rest }) {
  const classes = useStyles();

  const LinkComponent = component || "div";

  return (
    <LinkComponent className={classes.root} {...rest}>
      <div className={classes.icon}>{icon}</div>
      <div className={classes.title}>{title}</div>
    </LinkComponent>
  );
}

export default NavigationBarLink;
