import React from "react";
import { Link } from "react-router-dom";

import NavigationBar from "../components/NavigationBar";
import PeopleLogo from "../components/PeopleLogo";
import TopBar from "../components/TopBar";
import NavigationBarLink from "../components/NavigationBarLink";

import AlbumIcon from "@material-ui/icons/Album";

export default function NavBarContainer() {
  return (
    <NavigationBar>
      <TopBar>
        <PeopleLogo />
      </TopBar>
      <NavigationBarLink icon={<AlbumIcon />} title="Registrations" component={Link} to="/registrations" />
      <NavigationBarLink icon={<AlbumIcon />} title="Contractors" component={Link} to="/contractors" />
      <NavigationBarLink icon={<AlbumIcon />} title="Agencies" component={Link} to="/agencies" />
      <NavigationBarLink icon={<AlbumIcon />} title="Products" component={Link} to="/products" />
    </NavigationBar>
  );
}
