import React from "react";
import { useParams } from "react-router-dom";

import Page from "../components/Page";
import NavBarContainer from "../containers/NavBarContainer";
import Content from "../components/Content";
import TopBar from "../components/TopBar";

function Contractor() {
  const { id } = useParams();

  const contractorPageRef = React.useRef(null);

  React.useEffect(() => {
    if (contractorPageRef.current) {
      const promise = window.MODULE_LOADER.loadModule("contractors").then(() => {
        return window.CONTRACTORS.renderContractorForm({
          element: contractorPageRef.current,
          id: id
        });
      });

      return () => promise.then(unmount => unmount());
    }
  }, [contractorPageRef.current]);

  const commsRef = React.useRef(null);

  React.useEffect(() => {
    if (commsRef.current) {
      const promise = window.MODULE_LOADER.loadModule("internal-comms").then(() => {
        return window.INTERNAL_COMMS.renderCommsPanel({
          element: commsRef.current,
          itemId: id
        });
      });

      return () => promise.then(unmount => unmount());
    }
  }, [commsRef.current]);

  return (
    <Page>
      <NavBarContainer />
      <Content>
        <TopBar>Contractor</TopBar>
        <div style={{ height: "calc(100% - 64px)", display: "flex", flexGrow: 1 }}>
          <div style={{ height: "100%", width: "calc(100% - 400px)" }} ref={contractorPageRef}></div>
          <div style={{ height: "100%", width: 400 }} ref={commsRef}></div>
        </div>
      </Content>
    </Page>
  );
}

export default Contractor;
