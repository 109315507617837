import React from "react";

import Page from "../components/Page";
import Content from "../components/Content";
import TopBar from "../components/TopBar";
import NavBarContainer from "../containers/NavBarContainer";

function Contractors() {
  const contractorsPageRef = React.useRef(null);

  React.useEffect(() => {
    if (contractorsPageRef.current) {
      window.MODULE_LOADER.loadModule("contractors").then(() => {
        window.CONTRACTORS.renderContractorsTable({ element: contractorsPageRef.current });
      });
    }
  }, [contractorsPageRef.current]);

  return (
    <Page>
      <NavBarContainer />
      <Content>
        <TopBar>Contractors</TopBar>
        <div style={{ height: "calc(100% - 64px)" }} ref={contractorsPageRef}></div>
      </Content>
    </Page>
  );
}

export default Contractors;
