import React from "react";

import Page from "../components/Page";
import Content from "../components/Content";
import TopBar from "../components/TopBar";
import NavBarContainer from "../containers/NavBarContainer";

export default function Product() {
  const productsPageRef = React.useRef(null);

  React.useEffect(() => {
    if (productsPageRef.current) {
      window.MODULE_LOADER.loadModule("products").then(() => {
        window.PRODUCTS.renderProductsTable({
          element: productsPageRef.current
        });
      });
    }
  }, [productsPageRef.current]);

  return (
    <Page>
      <NavBarContainer />
      <Content>
        <TopBar>Products</TopBar>
        <div style={{ height: "calc(100% - 64px)" }} ref={productsPageRef}></div>
      </Content>
    </Page>
  );
}
