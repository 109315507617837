import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    height: 64,
    padding: "0px 24px",
    borderBottom: "solid 1px #dadce0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  }
});

function TopBar({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

export default TopBar;
