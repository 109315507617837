import React from "react";

import Page from "../components/Page";
import Content from "../components/Content";
import TopBar from "../components/TopBar";
import NavBarContainer from "../containers/NavBarContainer";

export default function Registrations() {
  const registrationsPageRef = React.useRef(null);

  React.useEffect(() => {
    if (registrationsPageRef.current) {
      window.MODULE_LOADER.loadModule("registrations").then(() => {
        window.REGISTRATIONS.renderRegistrationsTable({ element: registrationsPageRef.current });
      });
    }
  }, [registrationsPageRef.current]);

  return (
    <Page>
      <NavBarContainer />
      <Content>
        <TopBar>Registrations</TopBar>
        <div style={{ height: "calc(100% - 64px)" }} ref={registrationsPageRef}></div>
      </Content>
    </Page>
  );
}
