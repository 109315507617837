import React from "react";
import { useParams } from "react-router-dom";

import Page from "../components/Page";
import NavBarContainer from "../containers/NavBarContainer";
import Content from "../components/Content";
import TopBar from "../components/TopBar";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row"
  },
  agency: {
    height: "calc(100% - 64px)",
    width: "30%",
    marginRight: 10
  },
  branches: {
    width: "20%",
    marginRight: 10
  },
  consultants: {
    width: "20%",
    marginRight: 10
  }
});

export default function Agency() {
  const classes = useStyles();
  const agencyPageRef = React.useRef(null);
  const branchesPageRef = React.useRef(null);
  const contactsPageRef = React.useRef(null);
  const { id } = useParams();

  React.useEffect(() => {
    if (agencyPageRef.current) {
      window.MODULE_LOADER.loadModule("agencies").then(() => {
        window.AGENCIES.renderAgencyForm({
          element: agencyPageRef.current,
          id: id
        });
      });
    }
  }, [agencyPageRef.current]);

  React.useEffect(() => {
    if (branchesPageRef.current) {
      window.MODULE_LOADER.loadModule("agencies").then(() => {
        window.AGENCIES.renderBranchesTable({
          element: branchesPageRef.current,
          agencyId: id
        });
      });
    }
  }, [branchesPageRef.current]);

  React.useEffect(() => {
    if (contactsPageRef.current) {
      window.MODULE_LOADER.loadModule("agencies").then(() => {
        window.AGENCIES.renderContactsTable({
          element: contactsPageRef.current,
          agencyId: id
        });
      });
    }
  }, [contactsPageRef.current]);

  return (
    <Page>
      <NavBarContainer />
      <Content>
        <TopBar>Agency</TopBar>
        <div className={classes.root}>
          <div className={classes.agency} ref={agencyPageRef}></div>
          <div className={classes.branches} ref={branchesPageRef}></div>
          <div className={classes.consultants} ref={contactsPageRef}></div>
          <div>Comms?</div>
        </div>
      </Content>
    </Page>
  );
}
