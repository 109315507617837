import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  }
});

function Content({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

export default Content;
