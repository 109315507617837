import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    outline: "1px solid yellow",
    width: "100%",
    height: "100%",
    display: "flex",
    overflowY: "scroll"
  }
});

function Page({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

export default Page;
