import React from "react";
import { useParams } from "react-router-dom";

import Page from "../components/Page";
import NavBarContainer from "../containers/NavBarContainer";
import Content from "../components/Content";
import TopBar from "../components/TopBar";

export default function Product() {
  const productPageRef = React.useRef(null);
  const { id } = useParams();

  React.useEffect(() => {
    if (productPageRef.current) {
      window.MODULE_LOADER.loadModule("products").then(() => {
        window.PRODUCTS.renderProductForm({
          element: productPageRef.current,
          id: id
        });
      });
    }
  }, [productPageRef.current]);

  return (
    <Page>
      <NavBarContainer />
      <Content>
        <TopBar>Product</TopBar>
        <div style={{ height: "calc(100% - 64px)" }} ref={productPageRef}></div>
      </Content>
    </Page>
  );
}
