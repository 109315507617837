import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { StylesProvider, createGenerateClassName } from "@material-ui/core/styles";

import Agency from "./pages/Agency";
import Agencies from "./pages/Agencies";
import Contractor from "./pages/Contractor";
import Contractors from "./pages/Contractors";
import Login from "./pages/Login";
import Registrations from "./pages/Registrations";
import Registration from "./pages/Registration";
import Product from "./pages/Product";
import Products from "./pages/Products";

const generateClassName = createGenerateClassName({
  productionPrefix: "sp-",
});

function Router() {
  const [{ state, userId }, setAuthState] = React.useState(window.AUTH.getState());

  React.useEffect(() => {
    window.AUTH.subscribe(() => {
      console.log("subscription changed.");
      const newState = window.AUTH.getState();
      console.log(newState);
      setAuthState({ ...newState });
    });
    window.AUTH.startup();
  }, []);

  if (state === "PENDING") {
    return <div>pending...</div>;
  }
  if (state === "LOGGED_IN") {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <div>logged in {userId}.</div>
            </Route>
            <Route exact path="/registrations">
              <Registrations />
            </Route>
            <Route path="/registration/:id">
              <Registration isLead />
            </Route>
            <Route path="/registration-illustration/:id">
              <Registration />
            </Route>
            <Route exact path="/contractors">
              <Contractors />
            </Route>
            <Route path="/contractor/:id">
              <Contractor />
            </Route>
            <Route path="/agencies">
              <Agencies />
            </Route>
            <Route path="/agency/:id">
              <Agency />
            </Route>
            <Route path="/products">
              <Products />
            </Route>
            <Route path="/product/:id">
              <Product />
            </Route>

            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </StylesProvider>
    );
  } else {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <BrowserRouter>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Redirect from="*" to="/login" />
          </Switch>
        </BrowserRouter>
      </StylesProvider>
    );
  }
}

function Loader() {
  return (
    <div className="splash-screen">
      <div className="splash-logo"></div>
      <div className="message">Connecting...</div>
    </div>
  );
}

function App() {
  const [loaded, setLoaded] = React.useState(false);

  window.MODULE_LOADER.loadModule("auth")
    .then(() => setLoaded(true))
    .catch((error) => console.log("Failed to load auth module.", error));

  if (loaded) {
    return <Router />;
  } else {
    return <Loader />;
  }
}

export default App;
